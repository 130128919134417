export const state = () => ({
    roundInfo: null,
})


export const getters = {

}

export const mutations = {
    setRoundInfo(state, val) {
        state.roundInfo = val;
    },
}

export const actions = {
    
}