import { render, staticRenderFns } from "./legal.vue?vue&type=template&id=23f8da40&scoped=true&"
import script from "./legal.vue?vue&type=script&lang=js&"
export * from "./legal.vue?vue&type=script&lang=js&"
import style0 from "./legal.vue?vue&type=style&index=0&id=23f8da40&lang=less&scoped=true&"
import style1 from "./legal.vue?vue&type=style&index=1&lang=less&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23f8da40",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Layout: require('/Users/shkvo/github/pomelo/pomelo-frontend/components/layout/index.vue').default})
