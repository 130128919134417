//
//
//
//

import { DEFAULT_PROFILE } from "@/store/pfp"

export default {
  data() {
    return { };
  },
  props: {
    eosn_id: String,
    small: Boolean,
    medium: Boolean,
    large: Boolean,
  },
  components: {},
  computed: {
    border() {
      const { border, badge } = this.profile;

      // Season 1 Badge (Golden Pomelo & Season 1 Badge)
      if ( border.template_id == 1797 ) return "golden-border";
      else if ( badge.template_id ) return "golden-border";
      return "no-border";
    },
    size() {
      if ( this.small ) return "small";
      if ( this.medium ) return "medium";
      if ( this.large ) return "large";
      return "small";
    },
    profile() {
      const owner = this.$store.state.pfp.accounts[this.eosn_id];
      const profile = this.$store.state.pfp.profiles[owner] || DEFAULT_PROFILE(owner);
      return profile;
    },
    ipfs() {
      const { avatar, border, badge } = this.profile;

      // Eden Members
      if ( avatar.collection_name == "genesis.eden" && avatar.img ) return avatar.img;

      // Season 1 item holders
      if ( avatar.collection_name == "pomelo" ) return "QmZERnSdq3rGEAoEHAAozPprKV2nZQz38EEJ2y91MyCgJ4";
      if ( avatar.collection_name == ".gems" && avatar.schema_name == "pomelo.io" ) return "QmZERnSdq3rGEAoEHAAozPprKV2nZQz38EEJ2y91MyCgJ4";
      if ( border.template_id == 1797 ) return "QmZERnSdq3rGEAoEHAAozPprKV2nZQz38EEJ2y91MyCgJ4";
      if ( badge.template_id ) return "QmZERnSdq3rGEAoEHAAozPprKV2nZQz38EEJ2y91MyCgJ4";

      // default
      return "QmSZGEZGdwHbfqi62X25yRoHxyFHCd9HJR9TwG7Tt7BrjG";
    },
  },
  methods: {},
};
