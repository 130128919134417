//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Avatar from "@/components/pfp/avatar";

export default {
  components: {
    Avatar
  },
  data() {
    return {
        // Modals
        mobileMenuVisible: false,
        mobileMenuWalletInfoVisible: false,
        mobileMenuLangInfoVisible: false,
        mounted: false,
    }
  },
  computed: {
    userStore() {
      return this.$store.state.userStore;
    },
    cartStore() {
      return this.$store.state.cartStore;
    },
    locale() {
      return this.$store.state.languageStore.locale;
    },
    eosn_id() {
      return this.$store.state.wallet.eosn_id;
    },
    actor() {
      return this.$store.state.wallet.wallet.actor;
    },
    // new store
    balance() {
      return this.$store.state.wallet.balance;
    },
    linkedAccount() {
      return this.$store.state.wallet.linkedAccount;
    },
  },
  async mounted() {
    this.mounted = true
  },
  methods: {
    async showModal() {
      this.$store.dispatch('wallet/set_visible', true);
    },
    goToProfile() {
      console.log("here")
      this.$router.push({ path: "/profile" });
    },
    goToPaymentMethod() {
      this.mobileMenuVisible = false;
      this.mobileMenuWalletInfoVisible = false;
      this.$router.push({ path: "/profile/settings#paymentMethod" });
    },
    goToCart() {
      this.mobileMenuVisible = false;
      this.mobileMenuWalletInfoVisible = false;
      this.$router.push({ path: "/cart" });
    },
    toggleLangInfo() {
      this.mobileMenuLangInfoVisible = !this.mobileMenuLangInfoVisible;
    },
    toggleWalletInfo() {
      this.mobileMenuWalletInfoVisible = !this.mobileMenuWalletInfoVisible;
    },
    async onChange(visible) {
      this.mobileMenuVisible = visible;
    },
    setLocale(locale) {
      this.$store.commit("languageStore/set_locale", locale);
      this.$i18n.locale = locale
      this.$auth0.setUserLocale(locale)
    },
    async logout() {
      this.$store.commit("userStore/setUserProfile", null);
      this.$store.dispatch("wallet/set_eosn_id", null);
      this.$auth0.logout({ returnTo: window.location.origin });
    },
    login() {
      this.$auth0.loginWithRedirect();
    },
  },
}
