import util from "util";
import JSONBig from 'json-bigint'
import * as storage from "./storage"
// import 'dotenv/config'

let context;

function preProcessParams(params) {

  if(!params) return params;
  const res = Object.assign({}, params);

  // estimated_match:asc shim no longer needed - implemented on backend
  // if(res["sort[]"] == "estimated_match:asc") res["sort[]"] = "contributors:asc";
  // else if(res["sort[]"] == "estimated_match:desc") res["sort[]"] = "contributors:desc";

  return res;
}

function postProcessResults(url, method, params, results) {
  if(!results?.data) return results;

  // if(url == '/grants' && method == "GET" && params["sort[]"] == "estimated_match:asc")
  //   results.data = results.data.sort((a,b) =>
  //     +(a.active_round?.estimated_match ?? '0').replace(',','') - +(b.active_round?.estimated_match ?? '0').replace(',',''));
  // else if(url == '/grants' && method == "GET" && params["sort[]"] == "estimated_match:desc")
  //   results.data = results.data.sort((a,b) =>
  //     +(b.active_round?.estimated_match ?? '0').replace(',','') - +(a.active_round?.estimated_match ?? '0').replace(',',''));

  return results;
}

function apiFactory(url, method, contentType='application/json; charset=utf-8', needLanguageHeader = true) {
  return async function (data, params, ...url_params) {
    let requestUrl = url;
    if (url_params.length > 0) {
      requestUrl = util.format(url, ...url_params);
      console.log(requestUrl);
    }

    // default lang:
    let acceptLanguage = storage.get("Accept-Language") || "en";
    // let acceptLanguage = context.store.getters['userStore/getLanguageCode'];

    // TEMP - overide for backend language
    switch (acceptLanguage) {
      case "fr":
      case "es":
        acceptLanguage = "en";
      case "cn":
        acceptLanguage = "zh";
    }

    // console.log("context", context)
    // console.log("context.$auth0", context.$auth0)

    console.log('apiFactory',url);

    // update accessToken
    if(context.$auth0.isAuthenticated){
      try{
        const accessToken = await context.$auth0.getTokenSilently();
        // console.log('accessToken',accessToken);
        context.$axios.setToken(accessToken, 'Bearer');
      }
      catch(e){
        console.log('API: getTokenSilently failed, logout')
        context.$auth0.logout();
      }
    }
    // else{
    //   console.log('API: logout')
    //   context.$auth0.logout();
    // }

    let defaultsHeaders = context.$axios.defaults.headers.common;
    // console.log('defaultsHeader', defaultsHeaders)

    let headers = {...defaultsHeaders};
    headers['Content-Type'] = contentType;

    if (needLanguageHeader) {
      headers['X-Accept-Language'] = acceptLanguage;
    }
    // console.log('🥒', headers, url, method, data, params)

    return context.$axios({
      method: method,
      url: requestUrl,
      data: data,
      params: preProcessParams(params),
      headers: headers
    }).then(
      (res) => {
        // console.log("🌶",res);
        if (res.status !== 200 && res.status !== 201) throw res;
        return postProcessResults(url, method, params, res.data);
      },
      (err) => {
        // console.log("error:",err);
        if(err.name == "SyntaxError") return; // workaround for axios synthax error bug(?)
        throw err.response?.data?.errors?.[0]?.code || "something_went_wrong"
      });
  }
}

export const API = {
  // grants
  listGrants: apiFactory('/grants', "GET"),
  createGrants: apiFactory('/grants', "POST"),
  getGrants: apiFactory('/grants/by_name/%s', "GET"),
  updateGrants: apiFactory('/grants/by_name/%s', "POST"),
  deleteGrants: apiFactory('/grants/by_name/%s', "DELETE"),
  getGrantsContributions: apiFactory('/grants/by_name/%s/contributions', "GET"),
  getGrantsContributors: apiFactory('/grants/by_name/%s/contributors', "GET"),
  postGrantsFollows: apiFactory('/grants/by_name/%s/follows', "POST"),
  deleteGrantsFollows: apiFactory('/grants/by_name/%s/follows', "DELETE"),
  getGrantsMatchingEstimate: apiFactory('/grants/by_name/%s/matching_estimate', "GET"),
  reportGrants: apiFactory('/grants/by_name/%s/report', "POST"),
  retireGrants: apiFactory('/grants/by_name/%s/retire', "POST"),
  // getGrantsTranslations: apiFactory('/grants/by_name/%s/translations', "GET"),
  // addGrantsTranslations: apiFactory('/grants/by_name/%s/translations', "POST"),
  updateGrantsTranslations: apiFactory('/grants/by_name/%s/translations/%s', "POST"),
  getGrantsCategories: apiFactory('/grants/categories', "GET"),
  lookupGrantName: apiFactory('/grants/lookup/name', "GET"),

  // languages
  listLanguages: apiFactory('/languages', "GET"),

  // regions
  listRegions: apiFactory('/regions', "GET"),

  // matching rounds
  listRounds: apiFactory('/rounds', "GET"),
  listActiveRounds: apiFactory('/rounds/overview', "GET"),
  applyRound: apiFactory('/rounds/by_id/%s/apply', "POST"),

  // tokens
  listTokens: apiFactory('/tokens', "GET"),

  // upload
  uploadProjectLogo: apiFactory('/upload/project_logo', "POST", "multipart/form-data"),
  uploadUserAvatar: apiFactory('/upload/user_avatar', "POST", "multipart/form-data"),

  // user
  userGrants: apiFactory('/user/grants', "GET"),
  userProfile: apiFactory('/user/profile', "GET"),
  modifyUserProfile: apiFactory('/user/profile', "POST"),

  // users
  getUser: apiFactory('/users/by_username/%s', "GET"),
  getUserContributions: apiFactory('/users/by_username/%s/contributions', "GET"),
  repostUser: apiFactory('/users/by_username/%s/report', "POST"),
  userRegister: apiFactory('/users/register', "POST"),
  listUsernames: apiFactory('/users/usernames', "GET"),

  // proxied to account api
  postUserLinkedAccount: apiFactory(`/user/linked_accounts`, "POST", "application/json", false),
  deleteUserLinkedAccount: apiFactory(`/user/linked_accounts/by_name/%s`, "DELETE", "application/json", false),
  verifySocialsPort: apiFactory('/user/socials/port/verify', "POST", "application/json", false),
  getUserSocials: apiFactory('/user/socials', "GET", "application/json", false),
  getUserSocialsUrl: apiFactory('/user/socials/oauth2/verification_url', "GET", "application/json", false),

  //  kyc
  getKYCStatus: apiFactory('/user/kyc', "GET"),
  getKYCUrl: apiFactory('/user/kyc/verification_url?redirect_url=%s', "GET"),
}

export default ({ app }, inject) => {
  context = app;
  inject("api", API);

  context.$axios.defaults.transformResponse = [
    function (data) {
      return JSONBig.parse(data);
    }
  ]
}
