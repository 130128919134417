import * as pomelo from "pomelo-sdk";
import { Profile, PFP, LoginAccount } from "pomelo-sdk";
import * as storage from "../common/storage"

export const DEFAULT_PFP: PFP = {
  asset_id: 0,
  collection_name: "",
  img: "",
  schema_name: "",
  template_id: 0,
}

export function DEFAULT_PROFILE(owner: string): Profile {
  return {
    owner,
    avatar: DEFAULT_PFP,
    border: DEFAULT_PFP,
    badge: DEFAULT_PFP,
    created_at: "",
    last_updated: "",
  }
}

type Profiles = { [owner: string]: Profile };
type Accounts = { [eosn_id: string]: string };

interface State {
  profiles: Profiles;
  accounts: Accounts;
  updated: Date;
}

export const state = (): State => ({
  profiles: {},
  accounts: {},
  updated: new Date(0),
})

export const getters = {
  profile: (state: State) => (eosn_id: string) => {
    const owner = state.accounts[eosn_id];
    return state.profiles[owner] || DEFAULT_PROFILE(owner);
  },
  account: (state: State) => (eosn_id: string) => {
    return state.accounts[eosn_id];
  },
}

function set_default_pfp(profiles: any, owner: string, key: string) {
  if ( profiles[owner][key].asset_id ) return;
  profiles[owner][key] = DEFAULT_PFP;
}

function asset_id_to_number(profiles: any, owner: string, key: string) {
  if (!profiles[owner][key].asset_id) return;
  profiles[owner][key].asset_id = Number(profiles[owner][key].asset_id);
}

export const mutations = {
  set_profiles(state: State, payload: Profile[]) {
    if (!payload.length) return; // skip if empty
    const profiles: { [owner: string]: Profile } = {};
    for ( const row of payload ) {
      profiles[row.owner] = row;

      // normalize data & add defaults
      for ( const key of ["avatar", "border", "badge"]) {
        set_default_pfp(profiles, row.owner, key );
        asset_id_to_number(profiles, row.owner, key );
      }
    }
    state.profiles = profiles;
    state.updated = new Date();
    storage.set(storage.POMELO_PFP, state);
  },
  set_accounts(state: State, payload: LoginAccount[]) {
    if (!payload.length) return; // skip if empty
    const accounts: { [account: string]: string } = {};
    for ( const row of payload ) {
      accounts[row.user_id] = row.account;
    }
    state.accounts = accounts;
    state.updated = new Date();
    storage.set(storage.POMELO_PFP, state);
  },
}

export const actions = {
  async init(context: any) {
    const [ accounts, profiles ] = await Promise.all([
      pomelo.get_login_accounts(),
      pomelo.get_pfp_profiles()
    ]);
    console.log('store/pfp::init', { profiles, accounts });
    context.commit("set_accounts", accounts);
    context.commit("set_profiles", profiles);
  },
}
