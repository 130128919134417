interface State {
  search: string;
  sort_by: string;
  show_pending: boolean;
}

const valid_sort_by = new Set([
  "weighted_shuffle",
  "title:asc",
  "title:desc",
  "created_at:asc",
  "created_at:desc",
  "contributors:asc",
  "contributors:desc",
  "amount_raised:asc",
  "amount_raised:desc",
])

export const state = (): State => ({
  search: "",
  sort_by: new URLSearchParams(window.location.search).get("sort_by"),
  show_pending: new URLSearchParams(window.location.search).get("show_pending") == "true",
})

export const mutations = {
  set_search( state: any, value: string ) {
    console.log("🛒 store/search::mutations:set_search", value);
    state.search = value;
    const params = new URLSearchParams(state.search);
    state.sort_by = params.get("sort_by");
    state.show_pending = params.get("show_pending") == "true";
  },
  set_sort_by( state: any, value: string ) {
    console.log("🛒 store/search::mutations:set_sort_by", value);
    state.sort_by = value;
  },
  set_show_pending( state: any, value: boolean ) {
    console.log("🛒 store/search::mutations:set_show_pending", value);
    state.show_pending = value;
  },
}

export const actions = {
  async init(context: any) {
    const search = window.location.search;
    console.log('🛒 store/search::action:init', search );
    context.commit("set_search", search);
  },
  async set_sort_by(context: any, value: string) {
    console.log('🛒 store/search::action:set_sort_by', { value } );
    context.commit("set_sort_by", value);
  },
  async set_show_pending(context: any, value: boolean) {
    console.log('🛒 store/search::action:set_show_pending', { value } );
    context.commit("set_show_pending", value);
  },
}

export const getters = {
  getQueryString: (state) => (name) => {
    return new URLSearchParams(state.search).get(name);
  },
}
