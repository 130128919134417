import { EOSN_LOGIN_ACCOUNT, POMELO_FUNDING_ACCOUNT, POMELO_TOKEN_CONTRACT, POMELO_CLAIM_ACCOUNT } from './constants'

export function loginUnlink( user_id: string, account: string ) {
    console.log('payment-methods::postLink: user_id, account', user_id, account);
    return {
        account: EOSN_LOGIN_ACCOUNT,
        name: 'unlink',
        authorization: [{
            actor: account,
            permission: "active"
        }],
        data: {
            user_id,
            account,
        }
    }
}

export function loginLink( user_id: string, account: string, sig: string ) {
    return {
        account: EOSN_LOGIN_ACCOUNT,
        name: 'link',
        authorization: [{
            actor: account,
            permission: 'active'
        }],
        data: {
            user_id,
            account,
            sig
        }
    }
}

export function tokenTransfer( contract: string, from: string, to: string, quantity: string, memo: string ) {
    return {
        account: contract,
        name: 'transfer',
        authorization: [{
            actor: from,
            permission: 'active'
        }],
        data: {
            from,
            to,
            quantity,
            memo
        }
    }
}

interface Donation {
  grantName: string;
  tokenAmount: number;
  tokenSymbol: string;
}

export function cartTransfer( donations: Donation[], actor: string ) {
    const actions = [];
    for (const donation of donations) {
        const { grantName, tokenAmount, tokenSymbol } = donation;
        if (+tokenAmount == 0) continue;
        const quantity = `${tokenAmount} ${tokenSymbol}`;
        const memo = 'grant:' + grantName
        const action = tokenTransfer(POMELO_TOKEN_CONTRACT, actor, POMELO_FUNDING_ACCOUNT, quantity, memo )
        actions.push(action);
    }
    return actions;
}

export function claim(round_id: number, grant_id: string, actor: string ) {
    return [
        {
            account: POMELO_CLAIM_ACCOUNT,
            name: "claim",
            authorization: [
                {
                    actor,
                    permission: "active"
                }
            ],
            data: {
                round_id,
                grant_id
            }
        }
    ]
}
