import cloneDeep from 'lodash/cloneDeep'
import * as storage from "../common/storage"

export const state = () => ({
    grantList: [],
    isLoaded: false,
})

export const getters = {
    isExists: (state) => (grantName) => {
        let grantList = cloneDeep(state.grantList);
        return !!grantList.find(grant => grant.name == grantName);
    },
    getTokenListWithAmount: (state) => () => {
        const result = new Map();
        for ( const item of state.grantList ) {
            const tokenSymbol = item.currentToken;
            const tokenAmount = Number(item.tokenAmount);
            if (result.has(tokenSymbol)) {
                const totalAmount = (Number(result.get(tokenSymbol)) + tokenAmount).toFixed(4);
                result.set(tokenSymbol,totalAmount)
            } else {
                result.set(tokenSymbol,tokenAmount)
            }
        }
        return result;
    }
}

export const mutations = {
    setGrantList(state, val) {
        console.log('setGrantList', val)
        state.grantList = val;
        storage.set(storage.POMELO_CART, state.grantList);
    },
    setIsLoaded(state, val) {
        console.log('setIsLoaded', val)
        state.isLoaded = val;
    },
}

export const actions = {
    init(context) {
        console.log('cartStore/init')
        let grantList = storage.get( storage.POMELO_CART );
        if (grantList == null) {
            grantList = [];
        }
        context.commit("setGrantList", grantList);
        context.commit("setIsLoaded", true);
    },
    add(context, targetGrant) {
        let grantList = cloneDeep(context.state.grantList);

        // check
        const isExists = !!grantList.find(grant => grant.name === targetGrant.name);
        if(isExists) return;

        // add: current token, token amount
        targetGrant.tokenAmount = 1;
        targetGrant.currentTokenId = targetGrant.accepted_tokens[0].id;
        targetGrant.currentToken = targetGrant.accepted_tokens[0].symbol;

        grantList.push(targetGrant);
        context.commit("setGrantList", grantList);
    },
    remove(context, grantName){
        let grantList = cloneDeep(context.state.grantList);
        for (let i=0; i<grantList.length; i++) {
            let grant = grantList[i];
            if (grant.name === grantName) {
                grantList.splice(i,1);
                break;
            }
        }
        context.commit("setGrantList", grantList);
    },
    applyAll(context, grantName){
        let grantList = cloneDeep(context.state.grantList);
        const fromGrant = grantList.find(grant => grant.name === grantName);
        if (!fromGrant) return;

        grantList.forEach(grant => {
            if(grant.accepted_tokens.find(token => token.id === fromGrant.currentTokenId)){
                grant.tokenAmount = fromGrant.tokenAmount;
                grant.currentToken = fromGrant.currentToken;
            }
        })

        context.commit("setGrantList", grantList);
    },
    updateTokenAmount(context, params) {
        console.log('updateTokenAmount',params)
        const {grantName, tokenAmount} = params;

        let grantList = cloneDeep(context.state.grantList);
        const grant = grantList.find(gr => gr.name === grantName);
        if(!grant) return;
        grant.tokenAmount = tokenAmount;

        context.commit("setGrantList", grantList);
    },
    updateCurrentToken(context, params){
        const {grantName, symbol} = params;

        let grantList = cloneDeep(context.state.grantList);
        const grant = grantList.find(gr => gr.name === grantName);
        if(!grant) return;
        grant.currentToken = symbol;

        context.commit("setGrantList", grantList);
    },
    clear(context) {
        context.commit("setGrantList", []);
    },
}
