//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { toTitleCase } from "@/common/helpers";

export default {
  components: {},
  props: {},
  data() {
    return {
      changed: false,
      walletStatus: '',
    }
  },
  computed: {
    visible: {
      get() {
        const visible = this.$store.state.wallet.visible;
        if ( visible ) this.changed = false;
        return visible;
      },
      set(newValue) { this.$store.dispatch('wallet/set_visible', newValue); }
    },
    eosn_id() {
      return this.$store.state.wallet.eosn_id;
    },
    actor() {
      return this.$store.state.wallet.wallet.actor;
    },
    balance() {
      return this.$store.state.wallet.balance;
    },
    wallet() {
      return this.$store.state.wallet.wallet.wallet;
    },
    protocol() {
      return this.$store.state.wallet.wallet.protocol;
    },
    linkedAccount() {
      return this.$store.state.wallet.linkedAccount;
    },
    wallet_img() {
      if ( this.protocol == "anchor" || this.wallet == "anchor") return "~/assets/wallet/anchor.svg"
      else if ( this.wallet == "wombat") return "~/assets/wallet/wombat.png"
      else if ( this.wallet == "tokenpocket") return "~/assets/wallet/tokenpocket.png"
      else if ( this.wallet == "scatter" || this.protocol == "scatter") return "/assets/wallet/scatter.svg"
      else return "~/assets/wallet.svg"
    }
  },
  beforeMount() {

  },
  mounted () {

  },
  watch: {
    "$store.state.wallet.status":{
      handler: function(newVal,oldVal){
        this.walletStatus = newVal
      }
    }
  },
  methods: {
    toTitleCase,
    async onCancel(e) {
      this.$store.dispatch('wallet/set_visible', false);
    },
    async onOpen(e) {
      this.changed = false;
      this.$store.dispatch('wallet/set_visible', true);
    },
    log( method, params = {}) {
      console.log(`profile/settings/payment-methods::${method}`, params);
    },
    error( method, params = {}) {
      console.error(`profile/settings/payment-methods::${method}`, params);
    },
    async disconnect() {
      this.$store.dispatch("wallet/disconnect");
      this.changed = true;
    },
    async chooseWallet(e) {
      if(e.key == 'anchor') await this.connect_anchor()
      else await this.connect_scatter();
    },
    async connect_scatter() {
      this.$store.dispatch("wallet/login_scatter");
      this.changed = true;
    },
    async connect_anchor() {
      this.$store.dispatch("wallet/login_anchor");
      this.changed = true;
    },
    async connect_detect() {
      if ( this.$store.state.device.isMobile ) this.$store.dispatch("wallet/login_scatter");
      else this.$store.dispatch("wallet/login_anchor");
      this.changed = true;
    },
    async link() {
      const sig = await this.get_sig( this.actor );
      if ( !sig ) return;
      this.$store.dispatch("wallet/link", sig);
      this.changed = true;
    },
    async unlink() {
      this.$store.dispatch("wallet/unlink");
      this.changed = true;
    },
    /**
     * @returns {string|null} signature required for login.eosn::link
     * @example
     *
     * const sig = getSig("myaccount");
     * // => SIG_K1_KgJh3UXHUXuqVkyCAuFLpogUgBXmv2M9GiD5xpnySyybwwSE53cNMLQVHWW8G4jtxPHHu2rtiRJhkDwJA1iNCExc5pVZn8
     */
    async get_sig( account_name ) {
      this.log('getSig', { account_name });
      if ( !account_name ) throw new Error("[account_name] is required");
      try {
        const { data } = await this.$api.postUserLinkedAccount({ account_name });
        return data.sig;
      } catch (err) {
        this.$message.error(err.message || err, 8);
        this.error('getSig', { err })
      }
      return null;
    },
  }
}
