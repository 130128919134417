import { POMELO_TOKEN_SYMCODE } from "@/common/constants"

export const state = () => ({
  userProfile: null,  // {}

  // Contribution Stats
  total_contribution_stats: {
    amount: 0,                          // "80.32"
    contributions: 0,                   // 9
    total_contributions_rank: null      // 4
  },

  // EOSN account
  created_at: '',     // "2021-10-18T18:07:47Z"
  email: '',          // "<account>@gmail.com"
  eosn_id: '',        // "43512.d.eosn"
  name: '',           // "Denis 🚀"
  updated_at: '',     // "2021-10-21T00:55:48Z"
  username: '',       // "denis"
  website: '',        // "https://eosnation.io"

  // wallet details
  actor: '',
  permission: '',
  publicKey: '',
  wallet: '',
  protocol: '',
  chain: 'eos',

  // linked account
  linkedAccount: '',
  balance: `0.0000 ${POMELO_TOKEN_SYMCODE}`,
})

export const mutations = {
  setUserProfile(state: any, val: any) {
    state.userProfile = val;

    // Contribution Stats
    if ( !val?.total_contribution_stats ) return;
    const { amount, contributions, total_contributions_rank } = val.total_contribution_stats;
    state.total_contribution_stats = {
      amount: Number(amount),
      total_contributions_rank: total_contributions_rank,
      contributions: contributions
    }

    // EOSN account
    const { created_at, email, eosn_id, name, updated_at, username, website } = val;
    state.created_at = created_at;
    state.email = email;
    state.eosn_id = eosn_id;
    state.name = name;
    state.updated_at = updated_at;
    state.username = username;
    state.website = website;
  },

  setSaveWallet(state: any, payload: any) {
    const { actor, permission, publicKey, wallet, protocol, chain } = payload;
    console.log("setSaveWallet", payload);
    state.actor = actor;
    state.permission = permission;
    state.publicKey = publicKey;
    state.wallet = wallet;
    state.protocol = protocol;
    state.chain = chain;
  },
  setLinkedAccount(state: any, val: any) {
    state.linkedAccount = val;
  },
  setBalance(state: any, val: any) {
    state.balance = val;
  },
}

export const actions = {}
