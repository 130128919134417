// export separate icons here

export {
    // message icons
    CloseCircleFill,
    CheckCircleFill,
    CheckCircleOutline,
    ExclamationCircleFill,
    ExclamationCircleOutline,
    LoadingOutline,
    InfoCircleFill,
    // spinner
    Loading3QuartersOutline,
    // collapse header
    UpOutline,
    DownOutline,
    // pagination
    RightOutline,
    LeftOutline,
    //
    CloseOutline,
    CloseSquareOutline,
    CheckOutline,
    LinkOutline,
    ClockCircleFill,
    CopyOutline,
} from '@ant-design/icons';
