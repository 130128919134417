//
//
//
//
//
//
//
//
//
//

import Header from "./header";
import Footer from "./footer";

export default {
  components: {
    Header,
    Footer
  },
  async mounted() {
    this.$store.dispatch("device/init");
    this.$store.dispatch("languageStore/init");
    this.$store.dispatch("pfp/init");
    this.$store.dispatch("cartStore/init");
    this.$store.dispatch("wallet/init");
    this.$store.dispatch("search/init");
  },
};
