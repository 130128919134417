// EOSIO endpoints
export const EOSIO_CHAIN_ID = process.env.EOSIO_CHAIN_ID;
export const EOSIO_RPC = process.env.EOSIO_RPC;
export const EOSIO_EXPLORER = process.env.EOSIO_EXPLORER;

// Pomelo App Accounts
export const POMELO_FUNDING_ACCOUNT = process.env.POMELO_FUNDING_ACCOUNT;
export const POMELO_TOKEN_EXT_SYM = process.env.POMELO_TOKEN_EXT_SYM;
export const POMELO_CLAIM_ACCOUNT = process.env.POMELO_CLAIM_ACCOUNT;

// EOSN login account
export const EOSN_LOGIN_ACCOUNT = process.env.EOSN_LOGIN_ACCOUNT;

// Extended Token
const [sym, contract] = POMELO_TOKEN_EXT_SYM.split("@");
const [precision, symcode] = sym.split(",");
export const POMELO_TOKEN_CONTRACT = contract;
export const POMELO_TOKEN_PRECISION = precision;
export const POMELO_TOKEN_SYM = sym;
export const POMELO_TOKEN_SYMCODE = symcode;

// Pomelo configurations
export const GRANT_TITLE_MAX_LEN = process.env.GRANT_TITLE_MAX_LEN;
export const GRANT_DESCRIPTION_MAX_LEN = process.env.GRANT_DESCRIPTION_MAX_LEN;
export const CREATE_GRANT_BTN_ENABLED = process.env.CREATE_GRANT_BTN_ENABLED;

// Version control
export const GIT_REVISION = process.env.GIT_REVISION;

// API endpoints
export const BASE_API_URL = process.env.BASE_URL

// API endpoints
export const PORT_URL = process.env.PORT_URL ?? "https://portdevq8mjmnrw-portdev1.functions.fnc.fr-par.scw.cloud";

// Firebase analytics
export const FIREBASE_ANALYTICS_API_KEY = process.env.FIREBASE_ANALYTICS_API_KEY;
export const FIREBASE_ANALYTICS_AUTH_DOMAIN = process.env.FIREBASE_ANALYTICS_AUTH_DOMAIN;
export const FIREBASE_ANALYTICS_PROJECT_ID = process.env.FIREBASE_ANALYTICS_PROJECT_ID;
export const FIREBASE_ANALYTICS_STORAGE_BUCKET = process.env.FIREBASE_ANALYTICS_STORAGE_BUCKET;
export const FIREBASE_ANALYTICS_MESSAGING_SENDER_ID = process.env.FIREBASE_ANALYTICS_MESSAGING_SENDER_ID;
export const FIREBASE_ANALYTICS_APP_ID = process.env.FIREBASE_ANALYTICS_APP_ID;
export const FIREBASE_ANALYTICS_MEASUREMENT_ID = process.env.FIREBASE_ANALYTICS_MEASUREMENT_ID;
