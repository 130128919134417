//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import NavButton from "@/components/_generic/nav-button.vue";
import Header from "@/components/legal/header.vue";

export default {
  props: ["toc", "curPage"],
  data() {
    return {};
  },
  components: {
    NavButton,
    Header,
  },
  mounted() {},
  methods: {
    scrollToSmooth: (e, id) => {
      if (e) {
        e.preventDefault();
      }
      console.log(window.location);
      let elem = document.getElementById(id);
      let top = elem.offsetTop;
      window.scrollTo({ top, behavior: "smooth" });
      setTimeout(() => {
        // Set window location after smooth scroll
        // to highlight in toc.
        window.location.hash = id;
      }, 250);
    },
    redirectToPrivacy() {
      this.$router.push('/privacy');
    },
    redirectToTerms() {
      this.$router.push('/terms');
    }
  },
  computed: {
    documents() {
      return this.$store.state.legalStore.documents;
    },
    privacyTabActive() {
      return this.curPage && this.curPage.indexOf('privacy') !== -1;
    },
    termsTabActive() {
      return this.curPage && this.curPage.indexOf('terms') !== -1;
    }
  },
};
